<template>
  <div v-loading="isLoading" class="bg-white p-4 h-full text-black">
    <h2 class="fs-20 font-bold uppercase">{{ $t("Nạp điểm") }}</h2>

    <div class="py-2 mt-4">
      <div class="flex items-center mb-3" v-if="configData">
        <span class="fs-15">{{ $t("Tỷ lệ chuyển đổi:") }}</span>
        <div class="ml-2 fs-16">
          <span v-if="configData.bill_value" class="font-bold">{{
            appUtils.numberFormat(configData.bill_value, "VND")
          }}</span>
          <span class="mx-2">x</span>
          <span class="font-bold" v-if="configData.equivalent_point">{{
            appUtils.numberFormat(configData.equivalent_point, "Điểm")
          }}</span>
        </div>
      </div>
      <label class="required fs-15">{{ $t("Số tiền nạp") }}</label>
      <div class="flex flex-col md:flex-row gap-2 md:gap-4 md:items-center">
        <el-input
          :placeholder="$t('Nhập số tiền chuyển đổi')"
          class="cs-input flex-1"
          v-model="form.moneyValue"
          type="text"
          @input="handleChangeMoneyValue"
        >
          <template slot="append">VND</template>
        </el-input>
        <svg
          class="cs-icon"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.7073 16.7076L25.7073 20.7076C25.5197 20.8952 25.2652 21.0006 24.9998 21.0006C24.7344 21.0006 24.4799 20.8952 24.2923 20.7076C24.1047 20.5199 23.9993 20.2654 23.9993 20.0001C23.9993 19.7347 24.1047 19.4802 24.2923 19.2926L26.5861 17.0001H5.41356L7.70731 19.2926C7.89495 19.4802 8.00037 19.7347 8.00037 20.0001C8.00037 20.2654 7.89495 20.5199 7.70731 20.7076C7.51967 20.8952 7.26517 21.0006 6.99981 21.0006C6.73445 21.0006 6.47995 20.8952 6.29231 20.7076L2.29231 16.7076C2.19933 16.6147 2.12557 16.5044 2.07525 16.383C2.02493 16.2616 1.99902 16.1315 1.99902 16.0001C1.99902 15.8687 2.02493 15.7385 2.07525 15.6171C2.12557 15.4957 2.19933 15.3854 2.29231 15.2926L6.29231 11.2926C6.47995 11.1049 6.73445 10.9995 6.99981 10.9995C7.26517 10.9995 7.51967 11.1049 7.70731 11.2926C7.89495 11.4802 8.00037 11.7347 8.00037 12.0001C8.00037 12.2654 7.89495 12.5199 7.70731 12.7076L5.41356 15.0001H26.5861L24.2923 12.7076C24.1047 12.5199 23.9993 12.2654 23.9993 12.0001C23.9993 11.7347 24.1047 11.4802 24.2923 11.2926C24.4799 11.1049 24.7344 10.9995 24.9998 10.9995C25.2652 10.9995 25.5197 11.1049 25.7073 11.2926L29.7073 15.2926C29.8003 15.3854 29.874 15.4957 29.9244 15.6171C29.9747 15.7385 30.0006 15.8687 30.0006 16.0001C30.0006 16.1315 29.9747 16.2616 29.9244 16.383C29.874 16.5044 29.8003 16.6147 29.7073 16.7076Z"
            fill="#0C0C0D"
          />
        </svg>

        <el-input
          placeholder="Nhập số điểm chuyển đổi"
          class="cs-input flex-1 cs-disabled"
          v-model="pointsValue.round"
          :disabled="true"
        >
          <template slot="append">{{ $t("Điểm") }}</template>
        </el-input>
      </div>

      <div class="flex flex-col gap-1 mt-3">
        <label class="required">{{
          $t("Số điểm mỗi nhân sự nhận được")
        }}</label>
        <div class="flex flex-col md:flex-row gap-2">
          <div class="flex-1">
            <el-input
              placeholder="Nhập số điểm"
              class="cs-input flex-1"
              v-model="form.doctorPoints"
              @input="handleChangeDoctorPoints"
            >
              <template slot="append">{{ $t("Điểm") }}</template>
            </el-input>
            <small
              v-if="
                (!selectedDoctors || !selectedDoctors.length) &&
                form.doctorPoints
              "
              class="text--red"
              >{{ $t("Vui lòng chọn nhân sự được nạp điểm") }}</small
            >
          </div>
          <svg
            class="invisible cs-hidden-icon"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7073 16.7076L25.7073 20.7076C25.5197 20.8952 25.2652 21.0006 24.9998 21.0006C24.7344 21.0006 24.4799 20.8952 24.2923 20.7076C24.1047 20.5199 23.9993 20.2654 23.9993 20.0001C23.9993 19.7347 24.1047 19.4802 24.2923 19.2926L26.5861 17.0001H5.41356L7.70731 19.2926C7.89495 19.4802 8.00037 19.7347 8.00037 20.0001C8.00037 20.2654 7.89495 20.5199 7.70731 20.7076C7.51967 20.8952 7.26517 21.0006 6.99981 21.0006C6.73445 21.0006 6.47995 20.8952 6.29231 20.7076L2.29231 16.7076C2.19933 16.6147 2.12557 16.5044 2.07525 16.383C2.02493 16.2616 1.99902 16.1315 1.99902 16.0001C1.99902 15.8687 2.02493 15.7385 2.07525 15.6171C2.12557 15.4957 2.19933 15.3854 2.29231 15.2926L6.29231 11.2926C6.47995 11.1049 6.73445 10.9995 6.99981 10.9995C7.26517 10.9995 7.51967 11.1049 7.70731 11.2926C7.89495 11.4802 8.00037 11.7347 8.00037 12.0001C8.00037 12.2654 7.89495 12.5199 7.70731 12.7076L5.41356 15.0001H26.5861L24.2923 12.7076C24.1047 12.5199 23.9993 12.2654 23.9993 12.0001C23.9993 11.7347 24.1047 11.4802 24.2923 11.2926C24.4799 11.1049 24.7344 10.9995 24.9998 10.9995C25.2652 10.9995 25.5197 11.1049 25.7073 11.2926L29.7073 15.2926C29.8003 15.3854 29.874 15.4957 29.9244 15.6171C29.9747 15.7385 30.0006 15.8687 30.0006 16.0001C30.0006 16.1315 29.9747 16.2616 29.9244 16.383C29.874 16.5044 29.8003 16.6147 29.7073 16.7076Z"
              fill="#0C0C0D"
            />
          </svg>
          <div class="flex-1"></div>
        </div>
      </div>
      <SelectedDoctors
        @onSelectedDoctors="handleChangeSelectedDoctors"
        :selectedDoctors="selectedDoctors"
      />

      <div class="flex justify-end items-center gap-2 mt-2">
        <el-button @click="handleClose">{{ $t("Huỷ") }}</el-button>
        <el-button
          type="primary"
          @click="handleDepositPoints"
          :disabled="isLoading"
          class="text-white"
          >{{ $t("Nạp") }}</el-button
        >
      </div>
    </div>
  </div>
</template>
  
<script>
import appUtils from '../../../utils/appUtils'
import { DEPOSIT_SCOPE, HODO_CREDIT_TYPE } from '../../../utils/constants'
import SelectedDoctors from './SelectedDoctors.vue'

export default {
  name: 'DepositLayout',
  components: { SelectedDoctors },
  data () {
    return {
      form: {
        moneyValue: null,
        doctorPoints: null
      },
      HODO_CREDIT_TYPE,
      isLoading: false,
      selectedDoctors: [],
      configData: {},
      realData: {
        moneyValue: null,
        doctorPoints: null
      },
      appUtils
    }
  },
  computed: {
    pointsValue () {
      const points = this.realData.moneyValue / (this.configData?.rate || 0)

      return {
        round: this.roundToFourDecimals(points),
        value: points
      }
    }
  },
  watch: {},
  created () {
    this.getExchangeRateByOrgId()
  },
  methods: {
    async handleDepositPoints () {
      if (!this.$globalOrg?.id) return

      if (
        !this.realData.moneyValue ||
        Number(this.realData.moneyValue) <= 0 ||
        !this.pointsValue.value ||
        Number(this.pointsValue.value) <= 0 ||
        !this.realData.doctorPoints ||
        Number(this.realData.doctorPoints) <= 0
      ) {
        this.$message({
          message: this.$t('Vui lòng điền đầy đủ thông tin bắt buộc'),
          type: 'warning'
        })

        return
      }

      if (!this.selectedDoctors?.length) {
        this.$message({
          message: this.$t('Vui lòng chọn danh sách người nhận điểm'),
          type: 'warning'
        })

        return
      }

      if (
        this.realData.doctorPoints * this.selectedDoctors.length >
        this.pointsValue.value
      ) {
        this.$message({
          message: this.$t(
            'Số điểm mỗi nhân sự nhận được đang lớn hơn tổng số điểm'
          ),
          type: 'warning'
        })

        return
      }

      this.$confirm(
        'Bạn chắc chắn muốn nạp điểm cho những thành viên không?',
        'Nạp điểm',
        {
          dangerouslyUseHTMLString: true,
          cancelButtonText: this.$t('lbl_cancel'),
          confirmButtonText: this.$t('lbl_confirm')
        }
      )
        .then(async () => {
          try {
            const params = {
              tx_date: window.moment().valueOf(),
              org_id: this.$globalOrg.id,
              scope: DEPOSIT_SCOPE.org,
              scope_id: this.$globalOrg.id,
              // points: Number(this.realData.doctorPoints),
              // total_points: this.pointsValue.value,
              // rate: this.configData.rate,
              doctor_ids: this.selectedDoctors?.map((item) => item?.id),
              total_money: Number(this.realData.moneyValue)
            }
            this.isLoading = true

            const response = await this.$rf
              .getRequest('DoctorRequest')
              .postDepositPointsForDoctors(params)
            if (response.status === 200) {
              this.$message({
                message: this.$t('Nạp điểm thành công'),
                type: 'success'
              })

              this.handleClose()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.isLoading = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getExchangeRateByOrgId () {
      if (!this.$globalOrg?.id) return

      try {
        const params = {
          type: HODO_CREDIT_TYPE.deposit
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getExchangeRateByOrgId(this.$globalOrg.id, params)

        this.configData = response?.data?.[0] || {}
      } catch (error) {
        console.log(error)
      }
    },
    handleClose () {
      this.$router.replace({ name: 'HodoCreditDeposit' })
    },
    handleChangeSelectedDoctors (data) {
      this.selectedDoctors = data

      if (!data?.length) return

      if (this.pointsValue.value) {
        this.form.doctorPoints = this.roundToFourDecimals(
          this.pointsValue.value / data.length
        )

        this.realData.doctorPoints = this.pointsValue.value / data.length
      } else {
        const points = Number(
          this.form.doctorPoints?.toString()?.replaceAll(',', '')
        )

        this.form.moneyValue = this.roundToFourDecimals(
          points * data.length * this.configData.rate
        )

        this.realData.moneyValue = points * data.length * this.configData.rate
      }
    },
    handleChangeDoctorPoints (value) {
      const newValue = Number(value?.toString()?.replaceAll(',', ''))
      this.form.doctorPoints = appUtils.numberFormat(newValue)
      this.realData.doctorPoints = newValue

      if (!this.selectedDoctors?.length) return
      this.form.moneyValue = this.roundToFourDecimals(
        newValue * this.selectedDoctors.length * this.configData.rate
      )

      this.realData.moneyValue =
        newValue * this.selectedDoctors.length * this.configData.rate
    },
    handleChangeMoneyValue (value) {
      const newValue = Number(value?.toString()?.replaceAll(',', ''))
      this.form.moneyValue = appUtils.numberFormat(newValue)

      this.realData.moneyValue = newValue

      if (!this.selectedDoctors?.length) return

      this.form.doctorPoints = this.roundToFourDecimals(
        newValue / this.configData.rate / this.selectedDoctors.length
      )

      this.realData.doctorPoints =
        newValue / this.configData.rate / this.selectedDoctors.length
    },
    roundToFourDecimals (number) {
      if (!number) return ''
      const isInteger = Number.isInteger(number)

      let result
      if (isInteger) {
        result = appUtils.numberFormat(number)
      } else {
        const formatNumber = parseFloat(number).toFixed(4)
        const splitNumber = formatNumber.split('.')
        const intNumber = appUtils.numberFormat(splitNumber[0])
        result = `${intNumber}.${splitNumber[1]}`
      }

      return result
    }
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
      text-align: right;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }

  .cs-disabled {
    .el-input__inner {
      background-color: rgba(228, 228, 228, 0.823) !important;
      color: #373737 !important;
    }

    .el-input-group__append {
      background-color: rgba(228, 228, 228, 0.823) !important;
    }
  }
}

@media (max-width: 768px) {
  .cs-icon {
    transform: rotate(90deg);
    align-self: center;
    width: 24px;
    height: 24px;
  }

  .cs-hidden-icon {
    display: none;
  }
}
</style>