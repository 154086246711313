<template>
  <el-dialog :visible.sync="isOpen" width="60%" center custom-class="cs-dialog">
    <template slot="title">
      <div class="fs-20 font-bold uppercase">{{ $t('Chọn nhân sự nhận điểm') }}</div>
    </template>
    <div v-loading="isLoading">
      <div class="flex flex-col md:flex-row gap-2 md:items-center">
        <el-input
          clearable
          class="flex-1"
          v-model="searchValue"
          placeholder="Nhập tên nhân sự"
          @input="handleDebounce"
        ></el-input>
        <el-select
          class="flex-1 cs-select"
          clearable
          v-model="filter.orgId"
          filterable
          placeholder="Chọn Sub-org"
          @clear="handleClearSelectAll"
        >
          <el-option
            v-for="item in subOrgs"
            :key="item.id"
            :label="item.suborg_name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          class="flex-1 cs-select"
          clearable
          v-model="filter.wsId"
          filterable
          placeholder="Chọn workspace"
          @clear="handleClearSelectAll"
        >
          <el-option v-for="item in workspaces" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>

      <div class="mt-3 mb-2 flex flex-col md:flex-row gap-2 md:items-center justify-between">
        <div class>{{ `Đã chọn: ${totalSelected||0} nhân viên` }}</div>
        <el-button
          @click="handleSelectAll"
          type="primary"
          size="small"
        >{{ $t(isSelectAll?'Bỏ chọn tất cả':'Chọn tất cả') }}</el-button>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="listDoctors"
          style="width: 100%"
          border
          row-key="id"
          @selection-change="handleUpdateSelectedProducts"
          @select="handleSelectRow"
        >
          <el-table-column reserve-selection type="selection"></el-table-column>
          <el-table-column property="name" :label="$t('Tên nhân viên')"></el-table-column>
          <el-table-column property="email" :label="$t('Email')"></el-table-column>
          <el-table-column
            property="position"
            :formatter="row=>mdtUtils.getRoleMember(row&&row.doctor_infor&&row.doctor_infor.type)"
            :label="$t('Chức danh')"
          ></el-table-column>
        </el-table>
      </div>
      <AppPagination :paginationProps="pagination" @onChangePagination="getOrgMembers" />
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="flex gap-2 items-center">
        <el-button :disabled="isLoading" class="flex-1" @click="handleClose">{{ $t('Đóng') }}</el-button>
        <el-button
          :disabled="isLoading"
          class="flex-1"
          type="primary"
          @click="handleSubmit"
        >{{ $t('Xác nhận') }}</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { debounce } from 'lodash'
import AppPagination from '../../../components/Common/AppPagination.vue'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'ModalSelectDoctors',
  components: { AppPagination },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isSelectAll: false,
      searchValue: '',
      filter: {
        orgId: null,
        wsId: null
      },
      subOrgs: [],
      workspaces: [],
      listDoctors: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      mdtUtils,
      totalSelected: 0,
      selectedDoctors: [],
      allMembers: []
    }
  },
  watch: {
    filter: {
      deep: true,
      handler () {
        this.handleDebounce()
      }
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
    },
    handleSubmit () {
      this.$emit('onSelectedDoctors', this.selectedDoctors)
      this.isOpen = false
    },
    handleOpen (data) {
      this.isOpen = true
      this.selectedDoctors = [...data] || []
      this.getOrgMembers()
      this.getSubOrg()
      this.getWorkSpacesAllV2()
    },
    handleUpdateSelectedProducts (data) {
      // console.log(this.allMembers)

      if (this.isSelectAll) {
        // const newData = [...this.allMembers]
        // this.selectedDoctors = this.allMembers
        // this.totalSelected = this.allMembers?.length || 0
      } else {
        this.selectedDoctors = data
        this.totalSelected = data?.length || 0
      }
      //   this.$emit('onSelectedDoctors', data)
    },
    async getOrgMembers (data = {}, isSelectAll) {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          org_id: this.filter.orgId || this.$globalOrg.id,
          status: 1,
          page_size: data.pageSize || this.pagination.pageSize,
          page_num: data.currentPage || this.pagination.currentPage,
          keyword: this.searchValue,
          sort_by: 'created_at',
          order: 'desc'
        }

        if (this.filter.wsId) {
          params.ws_id = this.filter.wsId
          delete params.org_id
        }

        if (isSelectAll) {
          params.page_num = 1
          params.page_size = 10000
          params.keyword = ''
        }

        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsV2(params)

        if (isSelectAll) {
          return response.data?.data || []
        }

        this.listDoctors = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num || 1,
          pageSize: response.data?.page?.page_size || this.pagination.pageSize,
          totalItems: response.data?.page?.total || 0
        }

        if (this.isSelectAll) {
          this.$refs.multipleTable.clearSelection()
          this.listDoctors.forEach(item => {
            const index = this.allMembers.findIndex(
              selectedItem => selectedItem?.id === item?.id
            )
            const isSelected = index !== -1
            if (isSelected) {
              this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }

      //   this.totalItems = response.data?.meta?.total || 0
    },
    handleDebounce: debounce(function () {
      this.getOrgMembers({ currentPage: 1 })
    }, 500),
    async getWorkSpacesAllV2 () {
      if (!this.$globalOrg?.id) return

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getWorkSpacesAllV2(this.$globalOrg.id)
      this.workspaces = res.data?.reverse() || []
    },
    async getSubOrg () {
      if (!this.$globalOrg?.id) return

      const params = {
        page: 1,
        limit: 10000
      }

      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getSubOrganization(this.$globalOrg?.id, params)

        this.subOrgs = res.data?.data?.reverse() || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleSelectAll () {
      if (this.isSelectAll) {
        this.isSelectAll = false
        this.$refs.multipleTable.clearSelection()
        this.allMembers = []
        this.selectedDoctors = []
        this.totalSelected = 0
      } else {
        this.isSelectAll = true

        const allMembers = (await this.getOrgMembers({}, true)) || []
        this.allMembers = allMembers
        this.selectedDoctors = allMembers
        this.totalSelected = allMembers?.length
        this.listDoctors.forEach(item => {
          const index = this.allMembers.findIndex(
            selectedItem => selectedItem?.id === item?.id
          )
          const isSelected = index !== -1
          if (isSelected) {
            this.$refs.multipleTable.toggleRowSelection(item, true)
          }
        })
      }
    },
    handleSelectRow (selection, row) {
      if (this.isSelectAll) {
        const rowIndex = this.allMembers?.findIndex(
          item => item?.id === row?.id
        )
        if (rowIndex !== -1) {
          this.allMembers.splice(rowIndex, 1)
        } else {
          this.allMembers.push(row)
        }

        this.totalSelected = this.allMembers?.length
        this.selectedDoctors = this.allMembers
      }
      //   this.isSelectAll = false
    },
    handleClearSelectAll () {
      // this.isSelectAll = false
      // this.$refs.multipleTable.clearSelection()
      // this.allMembers = []
      // this.selectedDoctors = []
      // this.totalSelected = 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-dialog {
    border-radius: 8px;
  }

  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid #dcdfe6 !important;
    background-color: white !important;
  }

  .el-input-group__append {
    background-color: white !important;
  }

  .cs-disabled {
    .el-input__inner {
      background-color: rgba(228, 228, 228, 0.823) !important;
      color: #373737 !important;
    }

    .el-input-group__append {
      background-color: rgba(228, 228, 228, 0.823) !important;
    }
  }
}
</style>